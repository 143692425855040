@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;700&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~font-awesome/scss/font-awesome';

// Overriding how tailwind elements show the tab content
.tab-content > .tab-pane {
  display: block;
  height: 0;
  overflow: hidden;
}

.tab-content > .active {
  height: 100%;
  overflow: initial;
}

.accordion-item:last-of-type {
  border: 0;
}

.ellipsis {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ellipsis_tooltip {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.revert_ellipsis_tooltip {
  display: block !important;
  -webkit-line-clamp: unset !important;
  -webkit-box-orient: unset !important;
  overflow: unset !important;
  text-overflow: unset !important;
}

.chart-spinner {
  @apply text-lf-blue-00-9A fa fa-2x fa-circle-o-notch fa-pulse;
}

.not-integrated-icon {
  @apply text-red-600 fa fa-plug animate-pulse;
}

.highcharts-legend-item text {
  text-transform: capitalize;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: none !important;
  border-style: none !important;
  border-width: 0 !important;
}

.ng-select .ng-arrow-wrapper {
  @apply bg-[url('/assets/icons/normal_arrow_u13.svg')] mt-0.5 h-1.5 bg-no-repeat;
}

.ng-select .ng-dropdown-panel,
.ng-select.ng-select-opened .ng-select-container {
  z-index: 11 !important;
}

.list-style {
  a {
    @apply text-lf-blue underline;
  }
}
